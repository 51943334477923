import React from "react";
import OptiRoute from './OptiRoute'
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export class Dapp extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <OptiRoute />
      </div>
    );
  }
}