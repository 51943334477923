import React from "react";

export function InfoSection({title, contents}) {
  return (
    <div className="px-4 py-2">
      <div className="px-2 text-center text-xl">
        {title}
      </div>
      <div className="text-sm">
        {contents}
      </div>
    </div>
  );
}
