import React from "react";
import { ethers, BigNumber } from "ethers";
import OptiVaultArtifact from "../../contracts/OptiVault.json";
import Utils from '../Utils'
import OptiVaultSummary from './OptiVaultSummary';
import OptiVaultCampaignDetails from './OptiVaultCampaignDetails';
import TokenSelect from '../TokenSelect'
import Button from '../Button'
const optiSwap = require("../../contracts/opti-swap-address.json");
const supportedTokens = require("../../contracts/supported-tokens.json");
const optivaultData = require("../../contracts/optivault-data.json");
const zero = ethers.BigNumber.from(0);

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export class OptiVaults extends React.Component {
  static optiComponentName = "OptiVaults";

  static startingData = async(provider, signer, routeInfo, selectedAddress, ethBalance, safeGasPrice, utils) => {
    const {getToken} = utils
    const {tokenAddress, detailAddress} = routeInfo; 
    let data = {selectedAddress}
    let vaults, totalSupply;
    let token = await getToken(tokenAddress)
    if (optivaultData[token.pairAddress]) { 
      let cachedData = optivaultData[token.pairAddress]; //looks up a set of cached vaults by LP address
      vaults = cachedData.vaults
      data.totalSupply = ethers.BigNumber.from(cachedData.totalSupply)
      data.totalUserBalance = ethers.BigNumber.from(cachedData.totals[selectedAddress] || "0")
    } else {
       console.log("Exception")
    }
    if (vaults) {
      data = {...data, token, vaults, initialized: false}
      let vaultKeys = Object.keys(vaults)
      let matchedVault      
      for (let k of vaultKeys) {
        let lookupVault = vaults[k]
        if (lookupVault.address == detailAddress) {
          matchedVault = lookupVault
          continue
        }
      }
      if (detailAddress && matchedVault) {
        data["selectedVault"] = matchedVault;
      }
       

      if (data["selectedVault"]) {
        let v = data["selectedVault"]
        data.selectedVault = v
        vaults = {}
        vaults[v.address] = v
      }

      let keys = Object.keys(vaults)

      let newVaults = []

      for (let k of keys) {
        let newEntry = vaults[k]
          newEntry.contract = 
           new ethers.Contract(
           newEntry.address,
           OptiVaultArtifact.abi,
          signer
        )
        newEntry.address = k
        newEntry = utils.quickZero(newEntry, ["userBalance"])
        newEntry = utils.quickBN(newEntry, ["lockedUntilDate", "initialBalance", "currentBalance", "totalSupply", "balance"])
        newEntry = await this.refreshVault(data, selectedAddress, newEntry)
        newVaults.push(newEntry)
      }
      data.vaults = newVaults

      let timestamp = parseInt(Date.now() / 1000)
      data.vaults = data.vaults.sort( (a,b) => {
        if (a.lockedUntilDate.lt(timestamp)) {
          return 0
        }
        return a.lockedUntilDate.sub(b.lockedUntilDate)
      })
      data.initialized = true 
      return data
    } 
    return {selectedAddress, token: supportedTokens[tokenAddress], vaults:[], initialized: true}
  }

  async _claim() {
    let {data, makeTransaction} = this.props;
    let contract = data.selectedCampaign.contract;
    await makeTransaction(contract, "claim", [])
  }

  async _recover() {
    let {data, makeTransaction} = this.props;
    let contract = data.selectedCampaign.contract;
    await makeTransaction(contract, "recover", [])
  }

  static refreshVault = async(data, selectedAddress, vault) => {
    let {token, selectedVault, totalSupply} = data

    let {pair} = token
    let {tokenReserves, ethReserves} = pair
    let {initialBalance} = vault

    let currentBalance = BigNumber.from(vault.balance)
    //let totalSupply = BigNumber.from(vault.totalSupply)

    if (selectedVault) {
      currentBalance = await pair.contract.balanceOf(vault.address)
      totalSupply = await pair.contract.totalSupply()
      vault.withdrawable = await vault.contract.withdrawable();
    }

    let userBalance = vault.userBalance ? vault.userBalance : zero

    let timestamp = parseInt(Date.now() / 1000)
    if (timestamp > vault.lockedUntilDate) {
      vault.unlocked = true;
    }
    //user is viewing a detail of a vault. Need to load from network.
    if (selectedAddress) {
      if (selectedVault) {
        userBalance    = await vault.contract.tokenBalanceOf(selectedAddress)
        vault.withdrawable = await vault.contract.withdrawable();
      } else {
        let cachedUserVaultBalance = vault.balances[selectedAddress]
        if (cachedUserVaultBalance) {   
          userBalance    = BigNumber.from(cachedUserVaultBalance)
        }
        //try to read from the cache
      }
    }

    const withdrawn = initialBalance.sub(currentBalance);
    const withdrawalFeesPaid = withdrawn.div(4);
    const yieldEarned = withdrawalFeesPaid.mul(10000).div(currentBalance.sub(withdrawalFeesPaid))
    const yieldDisplay = (yieldEarned / 100).toFixed(2) + "%";
    vault.yieldDisplay = yieldDisplay

    vault.currentBalance = currentBalance
    vault.userBalance    = userBalance

    return vault
  }

  static populateVaults = async(data, provider, signer, selectedAddress, utils) => {
    let {pair, token, selectedCampaignAddress, selectedVault: vault} = data;
    if (!vault) {
      return {}
    }

    vault = utils.quickBN(vault, ["lockedUntilDate", "ethReserves", "tokenReserves", "initialBalance", "currentBalance", "totalSupply"])
 
    vault = await this.refreshVault(data, selectedAddress, vault) 
    data.selectedVault = vault 
    return data;
  }

  static refreshAllVaults = async (data) => {
    let {vaults, selectedAddress} = data
    let newVaults = []
    for (let v of vaults) {
      v = await this.refreshVault(data, selectedAddress, v)
      newVaults.push(v)
    }
    data.vaults = newVaults
    return data
  }

  static handleConnectedWallet = async (data, utils) => {
    data = this.refreshAllVaults(data)
    return data;
  }

  static updateBalances = async(provider, data, selectedAddress, ethBalance, utils) => {    
    data = await this.refreshAllVaults(data)
    return data;
  }

  static loading = () => {
    return `Loading OptiVault`
  }


  withdraw = async(vault) => {
    let {data, makeTransaction} = this.props
    await makeTransaction(vault.contract, "withdrawTokens", [])
  }  

  earlyWithdraw = async(vault) => {
    let {data, makeTransaction} = this.props
    await makeTransaction(vault.contract, "earlyWithdrawTokens", [])
  }

  selectedVault = async(vault) => {
    let {data, setData} =  this.props
    let data2 = {...data, selectedVault: vault}
    console.log("Assigning vault")
    await setData(data2)
  }

  lpToBalances = (lpBalance, data, utils) => {
    let {token, totalSupply} = data
    let {pair} = token
    let {tokenReserves, ethReserves} = pair

    let ethBal = lpBalance.mul(ethReserves).div(totalSupply)
    let tokenBal = lpBalance.mul(tokenReserves).div(totalSupply)
    return [utils.ethDisplay(ethBal), utils.tokenDisplay(tokenBal, token)]
  }

  vaultRender = (vault, data, utils) => {
    let {token, selectedVault} = data
    let {name, userBalance, currentBalance, yieldDisplay, lockedUntilDate, withdrawable, unlocked} = vault
    let {selectedAddress} = this.props
    window.data = data
    let {tokenReserves, ethReserves} = token.pair
    let matching = false
    if (selectedVault) {
      matching = vault.address.toLowerCase() === selectedVault.address.toLowerCase()
    }

    let [ethDisplay, tokenDisplay] = this.lpToBalances(currentBalance, data, utils)
    let [userEthDisplay, userTokenDisplay] = this.lpToBalances(userBalance, data, utils)

    let timeRemaining    = utils.timeRemaining(lockedUntilDate)
    let hasBalance       = !userBalance.isZero() 
    let buttonText       = "No balance"
    if (hasBalance) {
       buttonText =  withdrawable ? "Withdraw" : "Early withdraw (20% tax!)"
    }

     let buttonAction     = withdrawable ? async () => {await this.withdraw(vault)}
                                         : async () => {await this.earlyWithdraw(vault)}


    let details = null
    if (matching) {
      details = (<div>
              {hasBalance ? 
                 selectedAddress ? 
                 <Button className="" onClick={buttonAction} disabled={!hasBalance}
                  buttonText={buttonText} type={"proceed"} />
               : <div>Connect to view your balance.</div>
               : <div>.</div>}
            </div>)
     }

    //unlocked = !unlocked; //FIXME: This is not getting set correctly
    return (
      <div key={name} className="cardVault" onClick={ async() => {this.selectedVault(vault)}}>
        <div className="vaultHead">
          <div className="campaign">
            <img className="logo-hoge" src="hoge.png" alt="logo-hoge"></img>
            <h1 className="campaignName">{vault.name}</h1>
          </div>
          <div className="lockStatus">
            <span className="unlockMsg">{!unlocked && `Unlocks in ${timeRemaining}`}</span>
            <img className="badgeLockStatus" src={unlocked ? "badge-status-unlocked-2x.png" : "badge-status-locked-2x.png"} alt="iconLock">
            </img>
          </div>
        </div>
        <div className={hasBalance ? "vaultTail" : "vaultTail vaultTailMin"}>
          <div className="progressIndicator">
            <img src="icon-treasure-chest.svg" style={{height: "28px", minWidth: "28px"}}></img>
            <div className="progressBar">
              <div style={{textAlign: "center"}}>
                Campaign complete
              </div>
            </div>
          </div>
          <div className={hasBalance ? "vaultData" : "vaultData vaultDataMin"}>
            <div className="lpData">
              <span>{ethDisplay} ETH</span>
              <span>{tokenDisplay} {token.symbol}</span>
              {!unlocked && <span>{yieldDisplay} Yield</span>}
            </div>
            {hasBalance &&
            <>
              <img className="theThinGrayLine" src="line.svg"/>
              <div className="userData">
                <span>Your share of LP is worth</span>
                <span>{userEthDisplay} ETH</span>
                <span>{userTokenDisplay} {token.symbol}</span>
              </div>
            </>
            }
          </div>
        </div>
        {details}
     </div>
    )
  }


  
  render() {
    let {ethBalance, data, setData, txBeingSent, tokenSelectionRouteCallback, utils} = this.props
    let {initialized, token, totalUserBalance, selectedVault, selectedAddress, vaults} = data
    if (!vaults) {
      vaults = []
    }
    
    const vaultsDisplay = vaults.map( (v) => this.vaultRender(v, data, utils))
    let hasBalance = !totalUserBalance.isZero()
    let [totalEth, totalToken] = hasBalance ? this.lpToBalances(totalUserBalance, data, utils) : [0,0]
    
    return (
      <div className="optivaultContent">
        <div className="titleBar">
          <h1 className="titleBarHeader">OptiVault Summary</h1>
          <p className="titleBarText">Your GroupLP campaign balances and participation history</p>
        </div>
        <div className="cards">
          {hasBalance && <div className="cardBalance">
            <div className="balanceLabel">Your total GroupLP balance:</div>
            <div>ETH {totalEth}</div>
            <div>{token.name} {totalToken}</div>
          </div>}
          {vaultsDisplay}
        </div>
       </div>
    );
  } 
}

export default OptiVaults
