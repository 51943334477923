import React from "react";
import HomeCardTitle from './HomeCardTitle';
import HomeCardCheckMarks from './HomeCardCheckMarks';

export default function HomeCardGroupPool({token, tokenSelectionRouteCallback}) {
  let tokenAddress = "0xfAd45E47083e4607302aa43c65fB3106F1cd7607"
  let buttonText = "Go to GroupLP"

  if (token) {
    buttonText = `Go to ${token.symbol} GroupLP`
    tokenAddress = token.address;
  }

  return (
    <div>
      <HomeCardTitle
        title="GroupLP"
        icon="icon-treasure-chest.svg"
        enabled={true}/>
      <HomeCardCheckMarks
        onClick={async () => {
                    await tokenSelectionRouteCallback(`#pool/${tokenAddress}`, tokenAddress)
                  }}
        items={["Whales and fish team to improve LP health",
            "Reflective Staking time-locks",
            "Gamified alternative to Unicrypt"]}
        buttonText={buttonText}
        getInfo={() => {
                    window.open("https://resource.optiswap.pro/ethereum-contracts/grouplp")
                  }}
      />

    </div>
  );
}

