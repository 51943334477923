import React from "react";
import { ethers } from "ethers";
import Button from '../Button'
import Utils from '../Utils'

import PollOptions from './PollOptions'
import PaymentSummary from './PaymentSummary'
import CrowdFund from './CrowdFund'

export default class Post extends React.Component {
  state = {
    ethInput: "0",
  }

  render() {
    const { token, utils, campaign, approveCampaign, fundCampaign, selectedCampaign, committedTokens, selectedAddress} = this.props
    const { type, picture, title, author, body, logo, contribution } = campaign
    const { ethAmount, ethInput } = this.state

    return (
      <div className={"rounded-xl my-3 bg-gray-900"}>   
        <div>
          <div className="justify-center items-center">
            <div className="md:flex flex-row w-full justify-between items-center rounded-xl bg-black p-3">
              <div className="text-xl md:w-1/2 font-bold">
                {title}
              </div>
              <div>
                {type} by <div onClick={()=>{this.setState({showProfile:!this.state.showProfile})}}>{Utils.etherscanLink(author)}</div>
              </div>
            </div>


            <div className="p-3">
            <img src={picture}/>
            </div>

            <div className="m-3">
              {body}
            </div>

            {(type === "payment") &&
              <PaymentSummary payment={campaign.payment}/>}
            {(type === "poll" || type === "payment") &&
              <PollOptions options={campaign.options} token={token}/>}
            {(type === "fund") &&
              <CrowdFund crowdfund={campaign.crowdfund} utils={utils}/>}

          </div>
        </div>
      </div>
    )
  }
}
