import { ethers } from "ethers";
const zero = ethers.BigNumber.from(0);

export default class Utils {
  static quickBN = (obj, keys) => {
    for (let k of keys) {
      obj[k] = ethers.BigNumber.from(obj[k] ? obj[k] : "0")
    }
    return obj
  }
  static quickZero = (obj, keys) => {
    for (let k of keys) {
      obj[k] = zero
    }
    return obj
  }
  static time = (duration) => {
    let days = Math.floor(duration / (60 * 60 * 24));
    return days + " days"
  }
  static timeRemaining = (deadline) => {
      let timestamp = parseInt(Date.now() / 1000)
      const timeLeft = deadline.sub(timestamp).toString();
      if (timeLeft < 0) return "0"
      let hours = Math.floor(timeLeft  % (60 * 60 * 24) / (60 * 60));
      let days = Math.floor(timeLeft / (60 * 60 * 24));
      return days + " days " + hours + " hours";
  }
  static addressShortened = (address) => {
    return address ? address.substring(0,5) + "..." + address.substring(address.length - 5, address.length) : "";
  }
  static etherscanLink = (account) => {
    let {address, alias} = account
    if (alias == "") alias = this.addressShortened(address)
    return (<a className="underline" target="_blank" rel="noreferrer" href={`https://etherscan.io/address/` + address}>{alias}</a>)
  }

  static formatTransactionError = (txError) => {
    if (!txError) {
      return null;
    }

    if (txError.message) {
      if (txError.message.startsWith("VoidSigner")) {
        return "Connect wallet first."
      }
      return txError.message.split("[")[0]
    } else {
      return "Transaction Canceled."
    }
  }
  static tokenDisplay = (amount, token) => {
    if (amount.isZero()) {return "0";}
    let tokenBalance = ethers.utils.formatUnits(amount, token.decimals)
    let realDecimals = tokenBalance.split(".")[1].length;
    tokenBalance = tokenBalance.substring(0, tokenBalance.length - realDecimals + 4);
    return ethers.utils.commify(tokenBalance);
  }
  static ethDisplay = (amount, dec=5) => {
    if (amount.isZero()) {return "0";}
    return ethers.utils.formatEther(amount).substring(0,2 + dec);
  }
  static formatPercent = (numerator, denominator) => {
    const p = numerator.mul(10000).div(denominator).toString().padStart(4, "0");
    return p.slice(0,2) + "." + p.slice(2,4);
  }
  static getRpcErrorMessage = (error) => {
    if (error.data) {
      return error.data.message;
    }
    return error.message;
  }

  static getTokenOut(token, ethAmount, gasCost) {
    const {ethReserves, tokenReserves} = token.pair;
    if (ethReserves.isZero() || ethAmount.isZero()) {
      return ({tokenOut: zero, orderRealizePercent: 0});
    }
    ethAmount = ethAmount.mul(995).div(1000);
    let amountInWithFee = ethAmount.mul(997);
    let numerator = amountInWithFee.mul(tokenReserves);
    let denominator = ethReserves.mul(1000).add(amountInWithFee);
    let tokenOut = numerator.div(denominator)
    let tax = (token.buyTax || token.tax || 0)
    tokenOut = tokenOut.mul(1000-tax).div(1000)
    if (gasCost) {
      let paperValueBuy = tokenReserves.mul(ethAmount.add(gasCost)).div(ethReserves);
      let orderRealizePercent = Utils.formatPercent(tokenOut, paperValueBuy);
      return {tokenOut, orderRealizePercent}
    }
    return {tokenOut}
  }

  static getEthOut(token, tokenAmount, gasCost) {
    console.log(tokenAmount);
    const {ethReserves, tokenReserves} = token.pair;
    if (tokenReserves.isZero() || tokenAmount.isZero()) return ({ethOut: zero, orderRealizePercent: 0});
    let tax = (token.sellTax || token.tax || 0);
    let taxedTokenAmount = tokenAmount.mul(1000-tax).div(1000);
    let amountInWithFee = taxedTokenAmount.mul(997);
    let numerator = amountInWithFee.mul(ethReserves);
    let denominator = tokenReserves.mul(1000).add(amountInWithFee);
    let ethOut =  numerator.div(denominator);
    ethOut = ethOut.mul(995).div(1000);
    if (gasCost) {
      const paperValueSell = ethReserves.mul(tokenAmount).div(tokenReserves);
      let orderRealizePercent = Utils.formatPercent(ethOut.sub(gasCost), paperValueSell);
      return {ethOut, orderRealizePercent};
    }
    return {ethOut}
  }

}
