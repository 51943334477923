import React from 'react'

export default function({percent}) {
  return (
    <div className="relative p-3 md:h-8">
      <div className="w-full border-2 border-black content-center h-4 bg-gray-400 rounded-r-full">
          <div className={"h-full bg-emerald-700 text-center rounded-r-full"} style={{"width": percent +"%"}}>  
          </div>
      </div>
    </div>
  )
}
