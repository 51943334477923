import React from "react";
import HomeCardTitle from './HomeCardTitle';
import HomeCardCheckMarks from './HomeCardCheckMarks';

export default function HomeCardOptiGames({token, tokenSelectionRouteCallback}) {
  let buttonText = "Try Soon"

  return (
    <div>
      <HomeCardTitle
        title="OptiGames"
        icon="icon-gaming-pad-alt.svg"
        enabled={true}/>
      <HomeCardCheckMarks
        onClick={() => {return null}}
        items={["Token arcade infrastructure",
          "Fully on-chain game logic",
            "Multiplayer gaming with friends and foes "]}
        getInfo={async() => {
                    return null
                  }}
        getInfo={() => {
                    window.open("https://resource.optiswap.pro/internet-computer")
                  }}
        buttonText={buttonText}
      />

    </div>
  );
}

