import React from "react";
import { ethers } from "ethers";
import Button from '../Button'
import Bar from './Bar'
import Utils from '../Utils'

export default class PollOptions extends React.Component {

  render() {
    const { token, utils, options, approveCampaign, fundCampaign, selectedCampaign, committedTokens, selectedAddress} = this.props
    let voteValues = options.map(x => parseFloat(x.votes))
    let leadingVotes = Math.max(...voteValues)
    return (
      <div className={"items-center justify-center m-3 p-3 rounded-xl my-3 bg-gray-300 text-black"}>   
        {options.map((option) => {
          let percent = option.votes * 100 / leadingVotes
          return(<div>
            <div className="flex flex-row justify-between">
              <div>{option.text}</div>
              <div>{option.votes} {token.symbol}</div>
            </div>
              <Bar percent={percent}/>
            </div>)
        })}
      </div>
    )
  }
}
