import React from "react";
import { ethers, BigNumber} from "ethers";
import Utils from '../Utils'

import GroupLPArtifact from "../../contracts/GroupLP.json";
import TokenSelect from '../TokenSelect'

import GroupLPRow from './GroupLPRow'

import OptiVaultArtifact from "../../contracts/OptiVault.json";

import Button from '../Button'

const optiSwap = require("../../contracts/opti-swap-address.json");
const supportedTokens = require("../../contracts/supported-tokens.json");
const groupLPData = require("../../contracts/grouplp-data.json");
const opti = require("../../contracts/optivault-data.json");

const zero = BigNumber.from("0")

export class GroupLP extends React.Component {
  static optiComponentName = "GroupLP";

  state = {
    info: false
  }

  static loading = (address) => {
    return `Loading GroupLP: ${address}`
  }

  approveCampaign = async(campaign) => {
    let {data, makeTransaction} = this.props
    let {token} = data
    let contract = token.contract;
    await makeTransaction(contract, "approve", [campaign.address, campaign.commitment])
  }

  fundCampaign = async(campaign, weibn) => {
    let {data, makeTransaction} = this.props
    await makeTransaction(campaign.contract, "fund", [], weibn)
  }

  static startingData = async(provider, signer, routeInfo, selectedAddress, ethBalance, safeGasPrice, utils) => {
    const {getToken} = utils
    const {tokenAddress, detailAddress} = routeInfo;

    let token = await getToken(tokenAddress)

    let data = {selectedAddress, campaigns:[], totalUserBalance:zero};
    let cachedCampaigns;
    if (tokenAddress === "") {
      cachedCampaigns = groupLPData["topCampaigns"]
      console.log("Top campaigns")
    } else {
      cachedCampaigns = groupLPData[tokenAddress];
    }
    const gasCost = this.contributeGasCost(safeGasPrice)
    if (cachedCampaigns) {
      data = {...data, gasCost: gasCost, token: await getToken(tokenAddress), campaigns: cachedCampaigns, initialized: false}
      data = await this.populateCampaigns(data, ethBalance, provider, signer, selectedAddress, getToken);
      data.initialized = true
    } else {
      data = {selectedAddress, token: getToken(tokenAddress), campaigns:[], initialized: true} 
    }
    /// From Optivaults
    let vaults, totalSupply;
    if (opti[token.pairAddress]) { 
      let cachedData = opti[token.pairAddress]; //looks up a set of cached vaults by LP address
      vaults = cachedData.vaults
      data.totalSupply = ethers.BigNumber.from(cachedData.totalSupply)
      data.totalUserBalance = ethers.BigNumber.from(cachedData.totals[selectedAddress] || "0")
    } else {
       console.log("Exception")
    }
    if (vaults) {
      data = {...data, token, vaults, initialized: false}
      let vaultKeys = Object.keys(vaults)
      let matchedVault      
      for (let k of vaultKeys) {
        let lookupVault = vaults[k]
        if (lookupVault.address == detailAddress) {
          matchedVault = lookupVault
          continue
        }
      }
      if (detailAddress && matchedVault) {
        data["selectedVault"] = matchedVault;
      }
      if (data["selectedVault"]) {
        let v = data["selectedVault"]
        data.selectedVault = v
        vaults = {}
        vaults[v.address] = v
      }

      let keys = Object.keys(vaults)

      let newVaults = []

      for (let k of keys) {
        let newEntry = vaults[k]
          newEntry.contract = 
           new ethers.Contract(
           newEntry.address,
           OptiVaultArtifact.abi,
          signer
        )
        newEntry.address = k
        newEntry = utils.quickZero(newEntry, ["userBalance"])
        newEntry = utils.quickBN(newEntry, ["lockedUntilDate", "initialBalance", "currentBalance", "totalSupply", "balance"])
        newEntry = await this.refreshVault(data, selectedAddress, newEntry)
        newVaults.push(newEntry)
      }
      data.vaults = newVaults

      let timestamp = parseInt(Date.now() / 1000)
      data.vaults = data.vaults.sort( (a,b) => {
        if (a.lockedUntilDate.lt(timestamp)) {
          return 0
        }
        return a.lockedUntilDate.sub(b.lockedUntilDate)
      })
      data.initialized = true 
    }

    ///
    return data 
  }

  withdraw = async(vault) => {
    let {data, makeTransaction} = this.props
    await makeTransaction(vault.contract, "withdrawTokens", [])
  }  

  earlyWithdraw = async(vault) => {
    let {data, makeTransaction} = this.props
    await makeTransaction(vault.contract, "earlyWithdrawTokens", [])
  }

  selectedVault = async(vault) => {
    let {data, setData} =  this.props
    let data2 = {...data, selectedVault: vault}
    console.log("Assigning vault")
    await setData(data2)
  }

  lpToBalances = (lpBalance, data, utils) => {
    let {token, totalSupply} = data
    let {pair} = token
    let {tokenReserves, ethReserves} = pair

    let ethBal = lpBalance.mul(ethReserves).div(totalSupply)
    let tokenBal = lpBalance.mul(tokenReserves).div(totalSupply)
    return [utils.ethDisplay(ethBal), utils.tokenDisplay(tokenBal, token)]
  }

  static refreshVault = async(data, selectedAddress, vault) => {
    let {token, selectedVault, totalSupply} = data

    let {pair} = token
    let {tokenReserves, ethReserves} = pair
    let {initialBalance} = vault

    let currentBalance = BigNumber.from(vault.balance)
    //let totalSupply = BigNumber.from(vault.totalSupply)

    if (selectedVault) {
      currentBalance = await pair.contract.balanceOf(vault.address)
      totalSupply = await pair.contract.totalSupply()
      vault.withdrawable = await vault.contract.withdrawable();
    }

    let userBalance = vault.userBalance ? vault.userBalance : zero

    let timestamp = parseInt(Date.now() / 1000)
    if (timestamp > vault.lockedUntilDate) {
      vault.unlocked = true;
    }
    //user is viewing a detail of a vault. Need to load from network.
    if (selectedAddress) {
      if (selectedVault) {
        userBalance    = await vault.contract.tokenBalanceOf(selectedAddress)
        vault.withdrawable = await vault.contract.withdrawable();
      } else {
        let cachedUserVaultBalance = vault.balances[selectedAddress]
        if (cachedUserVaultBalance) {   
          userBalance    = BigNumber.from(cachedUserVaultBalance)
        }
        //try to read from the cache
      }
    }

    const withdrawn = initialBalance.sub(currentBalance);
    const withdrawalFeesPaid = withdrawn.div(4);
    const yieldEarned = withdrawalFeesPaid.mul(10000).div(currentBalance.sub(withdrawalFeesPaid))
    const yieldDisplay = (yieldEarned / 100).toFixed(2) + "%";
    vault.yieldDisplay = yieldDisplay

    vault.currentBalance = currentBalance
    vault.userBalance    = userBalance

    return vault
  }

  static populateVaults = async(data, provider, signer, selectedAddress, utils) => {
    let {pair, token, selectedCampaignAddress, selectedVault: vault} = data;
    if (!vault) {
      return {}
    }

    vault = utils.quickBN(vault, ["lockedUntilDate", "ethReserves", "tokenReserves", "initialBalance", "currentBalance", "totalSupply"])
  
    vault = await this.refreshVault(data, selectedAddress, vault) 
    data.selectedVault = vault 
    return data;
  }

  static refreshAllVaults = async (data) => {
    let {vaults, selectedAddress} = data
    let newVaults = []
    for (let v of vaults) {
      v = await this.refreshVault(data, selectedAddress, v)
      newVaults.push(v)
    }
    data.vaults = newVaults
    return data
  }

   static populateCampaigns = async(data, ethBalance, provider, signer, selectedAddress, getToken) => {
    const {token, gasCost} = data;
    for (let campaign of data.campaigns) {
      const {address} = campaign;
      if (token) {
        campaign.token = token
      } else {
        return {...data, campaigns: []}
      }

      //Populate / upconvert
      campaign.commitment = ethers.BigNumber.from(campaign.commitment);
      campaign.goalDate   = ethers.BigNumber.from(campaign.goalDate);
      campaign.lockDuration = ethers.BigNumber.from(campaign.lockDuration);
      campaign.lockedUntil = ethers.BigNumber.from(campaign.lockedUntil || "0");
      campaign.mintedLP = ethers.BigNumber.from(campaign.mintedLP || "0");
      campaign.lockedUntil = ethers.BigNumber.from(campaign.lockedUntil || "0"); 
      campaign.ethFunded = ethers.BigNumber.from(campaign.ethFunded || "0");
      campaign.ethGoal = zero;
      campaign.balance = zero;
      campaign.maximumAmount = zero;
      campaign.contribution = zero;
      campaign.progress = "0";


      campaign.contract =
        new ethers.Contract(
          address,
          GroupLPArtifact.abi,
          signer
        );

      await this.refreshCampaign(data, campaign, provider, selectedAddress, ethBalance);
      
    }
    return data;
  }

  static contributeGasCost = (safeGasPrice) => {
    let v = BigNumber.from("85000").mul(1).mul(10**9)
    let fee = ethers.utils.formatEther(v)
    return v
  }

  static refreshCampaign = async (data, campaign, provider, selectedAddress, ethBalance) => {

    const {gasCost} = data;

    if (campaign.status !== "done") {
      campaign.mintedLP = await campaign.contract.mintedLP();
      if (!campaign.mintedLP.isZero()) {
        campaign.status = "done";
        campaign.ethFunded = await campaign.contract.ethMatchEstimate();
        campaign.lockedUntil = await campaign.contract.withdrawalsLockedUntilTimestamp();
        campaign.optiVault = await campaign.contract.optiVault();
      }
    }

    if (campaign.status === "new") {
      if (await campaign.contract.supplierHasCommitedBalance()) {
        campaign.status = "funding"
      } 
    }

    if (campaign.status === "funding") {
      campaign.balance = await provider.getBalance(campaign.address)
      campaign.ethGoal = await campaign.contract.ethMatchEstimate()
      campaign.progress = campaign.balance.mul(100).div(campaign.ethGoal).toString();
      if (campaign.balance.gte(campaign.ethGoal)) {
        campaign.progress = "100"
        campaign.status = "full"
      }

      let maximumAmount = zero;
      maximumAmount = campaign.ethGoal.sub(campaign.balance);
      if (maximumAmount.gt(ethBalance)) {
        maximumAmount = ethBalance;
        if (maximumAmount.gt(gasCost)) {
          maximumAmount = maximumAmount.sub(gasCost);
        }
      }
      campaign.maximumAmount = maximumAmount;
    }

    if (selectedAddress && campaign.contract) {
      campaign.contribution = await campaign.contract.ethContributionOf(selectedAddress)
      if (campaign.status === "done") {
        if (selectedAddress) {
          //campaign.lpShares = await campaign.contract.sharesOf(selectedAddress) 
        }
      }
    }
  }

  static updateBalances = async(provider, data, selectedAddress, ethBalance, safeGasPrice, utils) => {
    if (!data || !data.campaigns) {
      console.log("PINEAPPLE 2 NO DATA")
      return data
    }
    for (let campaign of data.campaigns) {
      this.refreshCampaign(data, campaign, provider, selectedAddress, ethBalance)
    }
    data = await this.refreshAllVaults(data)

    return data
  }

  static handleConnectedWallet = async (data, utils) => {
    let {token} = data
    token = await utils.getToken(token.address, true);
    data = await this.refreshAllVaults(data)
    return {...data, token};
  }

  vaultRender = (vault, data, utils) => {
    let {token, selectedVault} = data
    let {name, userBalance, currentBalance, yieldDisplay, lockedUntilDate, withdrawable, unlocked} = vault
    let {selectedAddress} = this.props
    window.data = data
    let {tokenReserves, ethReserves} = token.pair
    let matching = false
    if (selectedVault) {
      matching = vault.address.toLowerCase() === selectedVault.address.toLowerCase()
    }

    let [ethDisplay, tokenDisplay] = this.lpToBalances(currentBalance, data, utils)
    let [userEthDisplay, userTokenDisplay] = this.lpToBalances(userBalance, data, utils)

    let timeRemaining    = utils.timeRemaining(lockedUntilDate)
    let hasBalance       = !userBalance.isZero() 
    let buttonText       = "No balance"
    if (hasBalance) {
       buttonText =  withdrawable ? "Withdraw" : "Early withdraw (20% tax!)"
    }

     let buttonAction     = withdrawable ? async () => {await this.withdraw(vault)}
                                         : async () => {await this.earlyWithdraw(vault)}


    let details = null
    if (matching) {
      details = (<div>
              {hasBalance ? 
                 selectedAddress ? 
                 <Button className="" onClick={buttonAction} disabled={!hasBalance}
                  buttonText={buttonText} type={"proceed"} />
               : <div>Connect to view your balance.</div>
               : <div>.</div>}
            </div>)
     }

    //unlocked = !unlocked; //FIXME: This is not getting set correctly
    return (
      <div key={name} className="" onClick={ async() => {this.selectedVault(vault)}}>
        <div className={hasBalance ? "" : ""}>

          <div className={hasBalance ? "flex-row" : "flex-row"}>
            <div className="flex flex-wrap">
              <div className="w-1/2">Current soft-locked holdings:</div>
              <div className="w-1/2">{tokenDisplay} {token.symbol}</div>
              <div className="w-1/2"/>
              <div className="w-1/2">{ethDisplay} ETH</div>
              {!unlocked && <div className="w-1/2">{yieldDisplay} Attrition Rewards</div>}
            </div>
            {hasBalance &&
            <>
              <img className="" src="line.svg"/>
              <div className="bg-green-700">
                <span>Your share of LP is worth:</span>
                <div>{userEthDisplay} ETH</div>
                <div>{userTokenDisplay} {token.symbol}</div>
              </div>
            </>
            }
          </div>
          <div className="w-1/2">
            <span className="">{!unlocked && `Unlocks in ${timeRemaining}`}</span>
            <img className="badge" src={unlocked ? "badge-status-unlocked-2x.png" : "badge-status-locked-2x.png"} alt="iconLock">
            </img>
          </div>
        </div>
        {details}
     </div>
    )
  }

  render() {
    const { initialized, address, addressDisplay, ethBalance, networkError,
    data,
    selectedAddress, utils
    } = this.props;
    let {token, totalUserBalance, selectedVault, vaults} = data
    const displayAddress = Utils.addressShortened(address)

    if (!vaults) {
      vaults = []
    }
    
    //const vaultsDisplay = vaults.map( (v) => this.vaultRender(v, data, utils))
    let hasBalance = !totalUserBalance.isZero()
    let [totalEth, totalToken] = hasBalance ? this.lpToBalances(totalUserBalance, data, utils) : [0,0]

    let { campaigns, selectedCampaign } = data
    
    if (selectedCampaign) {
      console.log("Selected a single campaign")
    }
    return (
      <div className="md:px-10 md:w-2/3 text-white h-auto"> 
        <div className="flex flex-row items-center justify-between">
          <div className = "md:w-1/2 p-2 text-left">
            <div className = "text-3xl font-bold md:py-5">
              {token && token.name} GroupLP
            </div>
            <div className="">
              The collaborative and gamified LP paradigm.
            </div>
          </div>
          {token &&
            <div className="w-full content-center items-center justify-center">
                <img alt="logo" className="h-32 w-32" 
                src={token.logo} />
            </div>}
        </div>

        {hasBalance && <div className="cardBalance md:w-2/3">
          <div className="balanceLabel">Your total GroupLP balance:</div>
          <div>ETH {totalEth}</div>
          <div>{token.name} {totalToken}</div>
        </div>}

        <div className="md:flex py-3 text-center items-center justify-between">
        <div name="top-panel" className={"w-full absolute left-0 md:relative md:py-1 gap-y-2 flex flex-col " + (selectedCampaign ? "md:rounded-t-xl" : "md:rounded-xl")}>
          {campaigns.map((campaign) => {
              let vault = vaults.find(x => x.address == campaign.optiVault)
              return (
                <div className={"rounded-xl my-3 bg-gray-900"}>
                <GroupLPRow key={campaign.address} campaign={campaign}
                 utils={utils}
                 selectCampaign={this.selectCampaign} 
                 approveCampaign={this.approveCampaign}
                 fundCampaign={this.fundCampaign}
                 selectedCampaign={selectedCampaign} selectedAddress={selectedAddress} 
                 />
                 {campaign.optiVault && this.vaultRender(vault, data, utils)}
                 </div>
            )})}
              </div>
        </div>

      </div>
    )
  }

 
}

export default GroupLP
