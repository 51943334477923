import React from "react";
import { InfoSection } from './InfoSection'
export function Info({close}) {
  return (
    <div className="justify-center font-bold items-center rounded-r-xl border-2 border-slate-700 fixed inset-0 z-50 overflow-y-auto outline-none focus:outline-none">
      <div className="relative mx-auto border-2 border-slate-700 max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-blue-900 outline-none focus:outline-none">
          {/*header*/}
          <div className="p-5 flex flex-row justify-between border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-3xl font-semibold">
              About OptiSwap
            </h3>
            <div className="right-3 text-right">
              <div className="opacity-60 hover:opacity-100 underline cursor-pointer" onClick = {close} target="_blank">
                  <img className="w-8 h-8" src={"/icon-close.png"} alt="close" />
              </div>
            </div>
          </div>
          <InfoSection
            title="What is OptiSwap?"
            contents="OptiSwap is a minimal interface to UniSwap v2 liquidity, with a number of enhancements. It’s a custom-made toolkit to improve trading experience and outcome, especially for taxed tokens."/>
          <InfoSection
            title="Front-run protection"
            contents="Instead of specifying slippage as a percent, OptiSwap uses an ETH size defaulting to .01 ETH. That way no matter how large the order is, bots can’t break even on transaction costs."/>
          <InfoSection
            title="Order Analysis"
            contents="Order analysis takes into account not just price impact (like UniSwap) but also token tax, to give a more accurate output amount and prevent confusion of slippage and tax."/>
          <InfoSection
            title="OptiSize"
            contents="The OptiSize formula, usually only approximated by whales, gives the optimal order size for balancing gas cost and price impact to realize the most out of your trades. Apply it with 1 click."/>
          <InfoSection
            title="Gas Optimization"
            contents="OptiSwap restricts to ETH-Token trades, which bypasses a lot of normal router logic to lower transaction fees - so the .5% OptiSwap fee doesn’t even create extra burden."/>
          <InfoSection
            title="GroupBuys"
            contents="OptiSwap hosts a simple community-building tool with low overhead, where people team up to reach a threshold of funds by a certain day, then share the basis price of a bulk purchase."/>
          <InfoSection
            title="GroupLP"
            contents="A provider offers their tokens for LP, if the community can match the ETH side. The LP is kept in an OptiVault for an agreed-upon period."/>
          <InfoSection
            title="OptiVault"
            contents="A Reflective Staking contract that allows early withdrawal, if you take a 20% haircut which is distributed to the other holders."/>
        </div>
      </div>
    </div>
  );
}
