import React from "react";
import HomeCardTitle from './HomeCardTitle';
import HomeCardCheckMarks from './HomeCardCheckMarks';

export default function HomeCardGroupPool({token, tokenSelectionRouteCallback}) {
  let tokenAddress = "0xfAd45E47083e4607302aa43c65fB3106F1cd7607"
  let buttonText = "Go to OptiBuys"

  if (token) {
    buttonText = `Go to ${token.symbol} OptiBuys`
    tokenAddress = token.address;
  }

  return (
    <div className="md:w-100 h-full mb-4 rounded-xl bg-gradient-to-bl from-blue-900 to-pink-900 text-white hover:opacity-100 md:flex md:flex-col">
      <HomeCardTitle
        title="OptiBuys"
        icon="icon-treasure-chest.svg"
        enabled={true}/>
      <HomeCardCheckMarks 
        onClick={async () => {
                    await tokenSelectionRouteCallback(`#pump/${tokenAddress}`, tokenAddress)
                  }}
        items={["Gamified group purchase campaigns",
            "Shared basis price",
            "Reflective soft-locks with paper-hand tax"]}
        buttonText={buttonText}
        getInfo={() => {
                    window.open("https://resource.optiswap.pro/ethereum-contracts/optibuys")
                  }}
      />

    </div>
  );
}

