import React from "react";
import { ethers } from "ethers";
import Button from '../Button'
import Utils from '../Utils'

import GroupLPProgressBar from './GroupLPProgressBar'

export default class GroupLPRow extends React.Component {
  state = {
    ethInput: "0",
  }

  render() {
    const { utils, campaign, approveCampaign, fundCampaign, selectedCampaign, committedTokens, selectedAddress} = this.props
    const { logo, token, contribution } = campaign
    const { ethAmount, ethInput } = this.state
    let balanceDisplay = "0"
    let goalDisplay = "0" 
    let buttonText
    let isSupplier = false
    let action = false

    if (campaign.ethGoal) {
      goalDisplay = Utils.ethDisplay(campaign.ethGoal) 
    }
    if (campaign.status === "funding") {
      balanceDisplay = Utils.ethDisplay(campaign.balance)
        action = (<div className="p-3 md:flex flex-row gap-4">
          <div className="md:w-1/2 flex flex-row bg-white text-black rounded focus:outline-none items-center p-2 mb-2">
            <div className="text-left underline cursor-pointer w-1/2"
              onClick={() => {
                 this.setState({ethInput: ethers.utils.formatEther(campaign.maximumAmount)}) 
                 }
}>
              MAX
            </div>
            <input 
              className="text-right w-1/2"
              type="number" step="any" name="ethIn" required 
              value = {ethInput} 
              placeholder="0"
              onChange={(e) => {
                 this.setState({ethInput: e.target.value})
               }
              }/>
          </div>
          <Button onClick={async () => {
                      await fundCampaign(campaign, ethers.utils.parseEther(ethInput))
                     }
                   } buttonText={"Contribute ETH!"} type={"proceed"} />
                   </div>)
    } else if (campaign.status === "new") {
      if (campaign.supplier === selectedAddress) {
        isSupplier = true
  
        action =  (<Button onClick={async () => {
                      await approveCampaign(campaign)
                     }
                   } buttonText={"APPROVE"} type={"pool"} />)

      } else {
        action  =  (<div>Waiting on Supplier to Approve</div>)
      } 
    } else if (campaign.status === "full") { 
        action  =  (<div>Campaign Successful! Waiting on SupplyLP.</div>)
    }

    const {name, commitment, goalDate, minEth, lockDuration, lockedUntil, initialLPTokens, burn, ethFunded} = campaign
    const lockDurationNumber = parseInt(lockDuration.toString())
    const done = (campaign.status === "done");

    const goalDateDisplay = Utils.timeRemaining(goalDate)
    const ethFundedDisplay = Utils.ethDisplay(ethFunded)
    const commitmentDisplay = `${Utils.tokenDisplay(commitment, campaign.token)}`
    const lockDurationDisplay = `${Utils.time(lockDurationNumber)}`
    let lockedUntilDisplay = ""
    if (done) {
      lockedUntilDisplay = `${Utils.timeRemaining(lockedUntil)}`
    } else {
      lockedUntilDisplay = `${lockDurationDisplay} lock`
    }
    const nameDisplay = name
//<div className = "h-6 justify-center">
    return (
      <div className={"rounded-xl" + (campaign.status.match("funding|new") ? " my-3 bg-gray-900" : "")}>   
        <div>
          <div className="">
            <div className="md:flex flex-row w-full justify-between items-center rounded-xl bg-black p-3">
              <div className="text-xl md:w-1/2 font-bold">
                {name}
              </div>
              <div className="md:w-1/4 flex flex-col">
                <div>
                  {commitmentDisplay} {campaign.token.symbol}
                </div>
                <div>
                  {done && " + " + ethFundedDisplay + " ETH added"}{!done && `${lockDurationDisplay} lock`}
                </div>
              </div>
            </div>
          </div>
          <div >
          {campaign.status.match("funding") && 
            <div className="">
              <div className="md:text-left px-3">
                {!campaign.contribution.isZero() && " (contributed " + ethers.utils.formatEther(campaign.contribution) + ")"}
              </div>
            <GroupLPProgressBar goalReached={campaign.goalReached} goalDateDisplay={goalDateDisplay} balanceDisplay={balanceDisplay} goalDisplay={goalDisplay} progress={campaign.progress} />
            </div>
          }
          </div>
          {action && <div className="mt-3 p-3">
            {action}
          </div>}
        </div>
      </div>
    )
  }
}
