import React from "react";
import { ethers, BigNumber } from "ethers";
import BonusSwapArtifact from "../../contracts/BonusSwap.json";
import TokenSelect from '../TokenSelect'
import 'bootstrap/dist/css/bootstrap.css';
import Utils from '../Utils'
const contractInfo = require("../../contracts/opti-swap-address.json");
const zero = ethers.BigNumber.from(0);

export class BonusSwap extends React.Component {

  static optiComponentName = "BonusSwap";
  constructor(props) {
    super(props);
    let {data} = props;
    this.initialState = {
      buying: true,
      tokenBalance: zero,
      tokenInput: "",
      ethInput: "",
      tokenIn: zero,
      ethBalance: zero,
      ethIn: zero,
      displaySettings: false,
      slippage: ".002", 
      deadline:"10",
      gasCost: 0,
      orderRealizePercent: "0",
      optiSize: zero,
      gasPrice: 30,
      networkError: undefined,
      token: {symbol: ""},
      log: []
    };
    this.counter = 0;
    this.state = this.initialState;
  }

  static loading = (address) => {
    return `Loading BonusSwap: ${address}`
  }

  clickedOnToken = async(address) => {
    const {getToken} = this.props
    let token = await getToken(address)
    this.setState({token})
  }


  static optimize(data, utils) {
    let {token, gasCost, gasPrice, buying} = data
    const {ethReserves, tokenReserves} = token.pair;
    if (ethReserves.isZero() || gasCost === 0) { 

      return data; }
    let sqrt = (x) => {
      const ONE = ethers.BigNumber.from(1);
      const TWO = ethers.BigNumber.from(2);
        let z = x.add(ONE).div(TWO);
        let y = x;
        while (z.sub(y).isNegative()) {
            y = z;
            z = x.div(z).add(z).div(TWO);
        }
        return ethers.BigNumber.from(y);
    }

    const originalGasCost = gasCost

    gasCost = this.totalCostEstimate(buying, gasPrice)
    gasCost = ethers.utils.parseUnits(gasCost.toString(), "gwei")

    if (!buying) {
      gasCost = gasCost.mul(tokenReserves).div(ethReserves);
      //originalGasCost = originalGasCost.mul(tokenReserves).div(ethReserves);
    }
    const g = sqrt(gasCost)
    const x = buying ? ethReserves : tokenReserves;
    const opti = g.mul(x).div(sqrt(x).add(g));
     let orderRealizePercent;
    if (buying) {
      orderRealizePercent = Utils.getTokenOut(token, opti, originalGasCost)["orderRealizePercent"];
    } else {
      orderRealizePercent = Utils.getEthOut(token, opti, originalGasCost)["orderRealizePercent"];
    }
    data.optiSize = opti
    data.optiPercent = orderRealizePercent
    return data
  }

  _useOptisize() {
    let {data, utils} = this.props
    let {initialized, buying, token, gasCost} = data
    data  = BonusSwap.optimize(data, utils)
    let optiSize = data.optiSize
    let {symbol, decimals} = token  
    if (!initialized) {
      this.log("Please connect to network to read liquidity pool data.")
      return;
    }
    if (optiSize.isZero()) {
      this.log("Waiting for pool data to calculate OptiSize.")
      return;
    }
    const display = buying ? Utils.ethDisplay(optiSize) : Utils.tokenDisplay(optiSize, token);

    this.log(`Gas cost estimate: ${Utils.ethDisplay(gasCost)} ETH, OptiSize ${display} ${buying ? "ETH" : symbol}`)

    if (buying) {
      this.setState({ethIn: optiSize, ethInput: ethers.utils.formatEther(optiSize)});
    } else {
      this.setState({tokenIn: optiSize, tokenInput: ethers.utils.formatUnits(optiSize, decimals)});
    }
  }

  _formatPercent (numerator, denominator) {
    const p = numerator.mul(10000).div(denominator).toString().padStart(4, "0");
    return p.slice(0,2) + "." + p.slice(2,4);
  }

  //Extract getTokenOut & _getEthOut with dependencies passed in (pair), gas is optional
  //if gas cost is supplied, calculate paperValueBuy & orderRealizePercent

  // limit logs to 5 messages
  static concatLogs = (logs) => {
    return (logs.length > 4) ? logs.slice(1,5) : logs;
  }

  concatLogs = (logs) => {
   return BonusSwap.concatLogs(logs)
  }

  log = (newMessage) => {
    let {setData, data} = this.props
    let {logs} = data
    logs = this.concatLogs(logs)
    const newEntry = `> ${newMessage}`
    const newLogs = logs.concat([newEntry])
    setData({...data, logs: newLogs})
  }

  _resetAmounts () {
    const zero = ethers.BigNumber.from(0);
    this.setState({
      tokenIn: zero,
      tokenOut: zero
    });
  }

  async getDeadline() {
    const {provider} = this.props;
    const blockNumBefore = await provider.getBlockNumber();
    const blockBefore = await provider.getBlock(blockNumBefore);
    const timestamp = blockBefore.timestamp;
    const deadline_seconds = this.state.deadline * 60;
    return timestamp + deadline_seconds;
  }

  _buyToken = async() => {
    let {data, makeTransaction, ethBalance} = this.props
    let {token, defaultSlippage} = data
    let {address, name, pair} = token
    let {ethReserves, tokenReserves} = pair
      let amountETH = this.state.ethIn;
      if (amountETH.isZero()) {
        this.log("Bought zero " + name + "! Congratulations.")
        return;
      }
      if (amountETH.gt(ethBalance)) {
        this.log("Trying to spend more than you have.")
        return;
      }
      let deadline = await this.getDeadline();
      const tolerance = ethers.utils.parseEther(defaultSlippage).mul(tokenReserves).div(ethReserves);
      let {tokenOut} = Utils.getTokenOut(token, amountETH);
      let amountOutMin = tokenOut.lt(tolerance) ? "0" : tokenOut.sub(tolerance);
      this.log("Sending buy transaction.")
      await makeTransaction(BonusSwap.bonusSwap, "buyToken", [address, amountOutMin, deadline], amountETH)
  }


  async _approveToken(contract) {
    let {data, makeTransaction} = this.props 
    let {token} = data
    let {symbol} = token
    const amount = ethers.constants.MaxUint256;
    this.log("Approving " + symbol);
    await makeTransaction(token.contract, "approve", [BonusSwap.address, amount]); 
  }

  static totalCostEstimate = (buying, gasPrice) => {
    const estimate = buying ? 120000 : 140000; //gas usage TODO: Update based on hoge exclusions
    const totalCost = estimate * gasPrice; //cost = gas usage * gas price 
    // return totalCost
    return BigNumber.from(totalCost.toString().split(".")[0])
  }

  static startingData = async(provider, signer, routeInfo, selectedAddress, ethBalance, safeGasPrice, utils) => {

    const {getToken} = utils
    let token = utils.emptyToken
    const {tokenAddress} = routeInfo
    if (tokenAddress) {
      token = await getToken(tokenAddress)
    }
    BonusSwap.bonusSwap = new ethers.Contract(
      contractInfo.bonusSwap,
      BonusSwapArtifact.abi,
      signer
    );
    let initialLogs = [`Loaded ${token.name} data.`, "Welcome to BonusSwap! Please enjoy your stay."];
    let data = {token, initialized: true, counter: 0, logs: initialLogs, optiSize: zero, gasCost: BonusSwap.totalCostEstimate(true, safeGasPrice), gasPrice: safeGasPrice, buying: true}
    data = this.optimize(data, utils)
    console.log("starting data ")
    console.log(token.slippage)
    if (token.slippage) {
      data["defaultSlippage"] = token.slippage;
      console.log("Got default slippage ", token.slippage)
    } else {
      data["defaultSlippage"] = ".002"
    }
    return this.updateBalances(provider, data, selectedAddress, ethBalance, safeGasPrice, utils)
  }

  static updateBalances = async(provider, data, selectedAddress, ethBalance, safeGasPrice, utils) => {
    if (!data || !data.token) {
      console.log("PINEAPPLE 2 NO DATA")
      return data
    }
    let newLogs = []
    let {token, counter, buying, gasPrice, logs} = data
    window.data = data
    let {symbol, pair} = token //balance is here too

    let tokenBalance = BigNumber.from(0)
    if (selectedAddress) {
      tokenBalance = await token.contract.balanceOf(selectedAddress);
    }
    let bonusBalance = await token.contract.balanceOf(BonusSwap.bonusSwap.address);
    //bonusBalance = ethers.utils.parseUnits("1000000", 18);
    data.bonusBalance = bonusBalance
    const oldEthBalance = ethBalance;
    if (!oldEthBalance.isZero() && !oldEthBalance.eq(ethBalance)) {
      if (oldEthBalance.gt(ethBalance)) {
        newLogs.push("Spent " + Utils.ethDisplay(oldEthBalance.sub(ethBalance)) + " ETH");
      } else {
        newLogs.push("Gained " + Utils.ethDisplay(ethBalance.sub(oldEthBalance)) + " ETH");
      }
    }    
    const oldTokenBalance = token.balance;
    if (!oldTokenBalance.isZero() && !oldTokenBalance.eq(tokenBalance)) {
      if (oldTokenBalance.gt(tokenBalance)) {
        newLogs.push("Spent " + Utils.tokenDisplay(oldTokenBalance.sub(tokenBalance), token) + " " + symbol);
      } else {
        newLogs.push("Gained " + Utils.tokenDisplay(tokenBalance.sub(oldTokenBalance), token) + " " + symbol);
      }
    }
    token.balance = tokenBalance
    data.token = token
    let oldEthReserves = pair.ethReserves;
    await utils.updateReserves(token.address);
    let updatedToken = await utils.getToken(token.address);
    let {ethReserves} = updatedToken.pair;
    if (!oldEthReserves.isZero() && !oldEthReserves.eq(ethReserves)) {
      if (oldEthReserves.gt(ethReserves)) {
        newLogs.push(Utils.ethDisplay(oldEthReserves.sub(ethReserves)) + " ETH sell occured");
      } else {
        newLogs.push(Utils.ethDisplay(ethReserves.sub(oldEthReserves)) + " ETH buy occured");
      }
    } else {
    }
    try {
      fetch("https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=4493VQY6KMG7CK7K37UYZP4ASA22RBD2AN")
      .then(async (response) => {
        const res = await response.json();
        if (res.result.SafeGasPrice) {
          const estimate = buying ? 170000 : 190000;
          const totalCost = estimate * gasPrice;
          data.gasCost = ethers.utils.parseUnits(totalCost.toString(), "gwei")
        }
      });
    } catch (error) {
      console.log("Gas oracle hiccuped.")
    }
    data = this.optimize(data, utils);
    data.counter = (counter + 1) % 4;
    data.logs = this.concatLogs(logs.concat([newLogs.join(", ")]))
    return data
  }

  static handleConnectedWallet = async (data, utils) => {
    let {token} = data
    token = await utils.getToken(token.address, true);
    return {...data, token};
  }


  render() {
    const {data, selectedAddress, connectWallet, ethBalance, setData, tokenSelectionRouteCallback, utils} = this.props
    if (!data || !data.token) {
      return (<div />)
    }
    let {buying, token, optiSize, optiPercent, initialized, gasCost, logs, bonusBalance} = data

    let {symbol, decimals} = token
    const {ethIn, tokenIn} = this.state
    const isWalletConnected = typeof selectedAddress !== 'undefined'

    const tokenBalance = Utils.tokenDisplay(token.balance, token);
    const ethBalanceDisplay = Utils.ethDisplay(ethBalance);
    let tokenOut, ethOut, orderRealizePercent;
    let bonusPercent = token.bonus;

    let bonusPercentDisplay = parseFloat(bonusPercent / 10).toFixed(1);
    let bonus = "0";
    let buyable = "0";
    let tokenOutNumber = 0
    let bonusNumber = 0
    let totalTokenOut = "0"
    if (buying) {
      let result = Utils.getTokenOut(token, ethIn, gasCost);
      orderRealizePercent = result.orderRealizePercent;
      tokenOutNumber = result.tokenOut
      tokenOut = Utils.tokenDisplay(tokenOutNumber, token);
      if (bonusPercent) {
        buyable = Utils.tokenDisplay(bonusBalance.mul(1000).div(bonusPercent), token)
        bonusNumber = result.tokenOut.mul(bonusPercent).div(1000).mul(98).div(100)
        bonus = Utils.tokenDisplay(bonusNumber, token)
        totalTokenOut = Utils.tokenDisplay(bonusNumber.add(tokenOutNumber), token)
      } else {
        totalTokenOut = Utils.tokenDisplay(tokenOutNumber, token)
      }
    } else {
      let result = Utils.getEthOut(token, tokenIn, gasCost);
      ethOut = Utils.ethDisplay(result.ethOut);
      orderRealizePercent = result.orderRealizePercent;
    }
    // optiSize = buying ? Utils.ethDisplay(optiSize) : Utils.tokenDisplay(optiSize, token);
    
    // let orderRealizePercentRoundedDown = orderRealizePercent > optiPercent ? optiPercent : orderRealizePercent

    initialized = true
    return (
      <div className="md:w-full justify-center">
        <div className="md:flex flex-row md:justify-center w-full items-center text-white font-bold md:p-10 ">
          <div className="flex justify-center">
            <img alt="logo" className="w-24 h-24 md:w-10 md:h-10" src={token.logo} />
          </div>
          <div className="p-2">The tax rebate solution for</div>
          <div  className="md:w-1/3 p-2">
            <TokenSelect
              token={token}
              selectionCallback = {async (value) => {
                await tokenSelectionRouteCallback(`#bonus/${value.address}`, value.address)
              }}/>
          </div>
        </div>

        <div className="flex flex-col items-center">
          {/* Main Panel */}
          <div name="mainPanel" className="relative flex flex-col md:flex-row justify-center p-3 gap-4 text-white w-[95%] max-w-3xl md:w-[90%] bg-slate-900 rounded-3xl">
            {/* Left Panel */}
            <div name="left-panel" className="relative flex flex-col items-center justify-between md:w-1/2 max-w-1/2 gap-2 font-poppins">
              <div className="flex flex-col flex-grow w-full items-center justify-between rounded-xl text-sm">
                {/* <h1 className='text-2xl'>Bonus Buy</h1> */}
                <div className="w-full flex flex-col justify-evenly">
                  <div className="flex flex-col justify-evenly w-full p-[0.15rem] bg-gradient-to-l from-pink-600 to-amber-300 rounded-3xl">
                    <div className="pt-3 pb-2 px-3 bg-slate-900 rounded-[1.4rem]">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-200">Available Bonus Buy</span>
                        {
                          typeof bonusPercent !== 'undefined'
                            ? <span className="text-[0.8rem] text-orange-400">Extra {bonusPercentDisplay}% per buy!</span>
                            : ''
                        }
                      </div>
                      <div className="flex justify-between items-end py-1 md:py-2 w-full">
                        <input 
                          className="py-1 w-[80%] text-2xl md:text-3xl font-semibold bg-transparent focus:outline-none opacity-100"
                          value = {buyable}
                          disabled
                          placeholder="0"
                        />
                        <span className="mb-2">&nbsp;{symbol}</span>
                      </div>
                    </div>
                  </div>
                  <div className="py-2 md:p-0">
                    <div className="flex flex-col justify-evenly w-full pt-1 px-3 mb-1">
                      <span className="text-gray-400">Purchase Amount</span>
                      <div className="flex justify-between items-end">
                        <b className="max-w-[85%] overflow-hidden text-lg text-ellipsis">{tokenOut}</b>
                        &nbsp;{symbol}
                      </div>
                    </div>
                    <div className="flex flex-col justify-evenly w-full px-3">
                      <span className="text-gray-400">Bonus Amount</span>
                      <div className="flex justify-between items-en">
                        <b className={`max-w-[85%] overflow-hidden text-lg text-ellipsis ${bonus === '0' ? 'text-white' : 'text-green-400'}`}>{bonus}</b>
                        &nbsp;{symbol}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="flex items-center p-2.5 w-full justify-center text-lg cursor-pointer rounded-xl shadow-[0_0_0_2px_#bbb_inset] hover:bg-slate-800 transition duration-175"
                onClick = {() => this._useOptisize()}
              >
                Use optimal size
              </button>
            </div>

            {/* Right Panel */}
            <div name="right-panel" className="flex flex-col gap-2 items-center justify-between md:w-1/2">
              <div className="relative flex flex-col items-center justify-center w-full">
                {/* "From" Form */}
                <div className="flex flex-col mb-1 p-3 w-full bg-slate-bg rounded-xl focus-within:shadow-[0_0_0_1px_#aaa_inset]">
                  <div className="flex justify-between">
                    <span className="text-slate-400 text-sm">From</span>
                    <span className="text-slate-400 text-sm">
                      Balance: {isWalletConnected
                        ? (buying ? `${ethBalanceDisplay} ETH` : `${tokenBalance} ${token.symbol}`)
                        : 'N/A'
                      }
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <input 
                      className="py-1 w-[70%] h-12 text-3xl font-semibold bg-transparent focus:outline-none"
                      type="number"
                      step="any"
                      name={!buying ? "tokenIn" : "ethIn"}
                      required
                      value = {!buying ? this.state.tokenInput : this.state.ethInput} 
                      disabled = {!initialized}
                      placeholder="0"
                      // prevent user from entering e/E for scientific notation
                      onKeyDown={(e) => (e.key.toLowerCase() === 'e' || e.key === '-') && e.preventDefault()}
                      onChange={
                        (event) => {
                          const val = event.target.value
                          if (val === 'e') {
                            return
                          }
                          // stringify empty string to '0' for bn_val
                          let stringifiedVal = val
                          if (stringifiedVal === '') {
                            stringifiedVal = '0'
                          }
                          let bn_val = !buying ? ethers.utils.parseUnits(stringifiedVal, decimals) : ethers.utils.parseEther(stringifiedVal)
                          this.setState(!buying ? { tokenIn: bn_val, tokenInput: val } : { ethIn: bn_val, ethInput: val })
                        }
                      }
                    />
                    <div className="w-1/4 text-right p-1 text-xl">{buying ? "ETH" : token.symbol}</div>
                  </div>
                </div>
                {/* "To" Output */}
                <div className="flex flex-col justify-between p-3 w-full bg-slate-bg rounded-xl">
                  <div className="flex justify-between">
                    <span className="text-slate-400 text-sm">To</span>
                    <span className="text-slate-400 text-sm">
                      Balance: {isWalletConnected
                        ? (buying ? `${tokenBalance} ${token.symbol}` : `${ethBalanceDisplay} ETH`)
                        : 'N/A'
                      }
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <input 
                      className="py-1 w-[70%] h-12 text-3xl font-semibold bg-transparent focus:outline-none opacity-100"
                      value = {buying ? totalTokenOut : ethOut}
                      disabled
                      placeholder="0"
                    />
                    <div className="w-1/4 text-right p-1 text-xl">{buying ? token.symbol : "ETH"}</div>
                  </div>
                </div>
              </div>
              {/* Trade Button */}
              <button
                className="flex items-center p-2.5 w-full justify-center text-lg cursor-pointer rounded-xl bg-gradient-to-l from-indigo-800 to-sky-600 hover:saturate-150 transition duration-175"
                type="submit"
                disabled = {!initialized}
                onClick = {
                  isWalletConnected
                    ? (buying ? () => this._buyToken() : () => this._sellToken())
                    : () => connectWallet()
                }
              >
                {
                  isWalletConnected
                    ? `${buying ? "BUY" : "SELL"} ${symbol}`
                    : 'Connect Wallet'
                }
              </button>
            </div>
          </div>

          <div className="flex justify-center w-full h-full">
          {(logs.length > 0) && 
          <div className = "md:w-1/2 justify-center md:1/6">
            <div name="logger" 
          className = "w-full h-auto mt-5 break-words font-mono border-2 border-gray-500 text-left bg-black text-white">
            {logs.map((logRow, index) => {
              return(
              <div key={`log${index}`}>
                {logRow}
              </div>);})}
            </div>
          </div>}
          </div>

          {this.state.displaySettings && <div
            className="justify-center items-center flex rounded-r-xl border-2 border-slate-700 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto border-2 border-slate-700 max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-200 outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    SETTINGS
                  </h3>
                </div>
                <div className="relative p-6 flex-auto">
                <div className="w-64 flex grid-cols-2 text-sm">
                  If your slippage tolerance is less than the transaction cost for a bot to buy and sell,
                  you won't be worth sandwiching. There probably isn't a good reason to increase it! Come talk about it on Discord. Change at your own risk.
                </div>
                  <div className="w-full flex grid-cols-2 text-sm">
                    <div className= "w-1/4 p-1">Slippage: </div>
                    <div className="w-1/2 flex grid-cols-2 gap-0 align-middle">
                      <input 
                        className="text-right p-1 rounded w-1/2 focus:outline-none" type="number" step="any" name="tokenIn" required 
                        value = {data.defaultSlippage} 
                        disabled = {!initialized}
                        placeholder="0"
                        onChange={
                          (event) => {
                              let val = event.target.value;
                              setData({...data, defaultSlippage: val})
                            }
                        }/>
                      <div className="w-1/4 text-right p-1">ETH</div>
                    </div>
                  </div>
                  <div className="w-full flex grid-cols-2 text-sm">
                    <div className= "w-1/4 p-1">Deadline: </div>
                    <div className="w-1/2 flex grid-cols-2 gap-0 align-middle">
                      <input 
                        className="text-right p-1 rounded w-1/2 focus:outline-none" type="number" step="any" name="tokenIn" required 
                        value = {this.state.deadline} 
                        disabled = {!initialized}
                        placeholder="0"
                        onChange={
                          (event) => {
                              let val = event.target.value;
                              this.setState({deadline: val})
                            }
                        }/>
                      <div className="w-1/4 text-right p-1">minutes</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {this.setState({displaySettings: false})}}>
                      SAVE AND CLOSE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>}
      
          <div className="md:absolute left-10 bottom-10 font-bold justify-center h-2 text-white">
            {initialized && !this.state.displaySettings &&
              <div className="text-center cursor-pointer text-xs">
                <div className="underline" onClick= {() => this.setState({displaySettings: true})}>
                  SETTINGS
                </div>
              </div>}
          </div>
        </div>
      </div>
    );
  }

}

export default BonusSwap;
