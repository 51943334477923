import React from "react";
import { ethers } from "ethers";
import Button from '../Button'
import Utils from '../Utils'

export default class PaymentSummary extends React.Component {

  render() {
    const { payment} = this.props
    const {tokenName, displayAmount, from, to, fundingApproved} = payment
    return (
      <div className={"rounded-xl my-3 bg-gray-500 text-black p-3 m-3"}>   
          {Utils.etherscanLink(from)} -------{displayAmount} {tokenName}-----> {Utils.etherscanLink(to)}
          {(fundingApproved) ?
            <div>Funding has been approved.</div> :
            <div>Funding has not been approved.</div>
          }
      </div>
    )
  }
}
