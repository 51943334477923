import React from "react";
import { ethers, BigNumber} from "ethers";
import Utils from '../Utils'

const optiSwap = require("../../contracts/opti-swap-address.json");

const zero = BigNumber.from("0")

export class TokenLaunch extends React.Component {
  static optiComponentName = "TokenLaunch";

  state = {
    info: false
  }

  static loading = (address) => {
    return `Loading TokenLaunch: ${address}`
  }

  approv
  static startingData = async(provider, signer, routeInfo, selectedAddress, ethBalance, safeGasPrice, utils) => {
   
    let cost = ethers.utils.parseEther("0.5");
    return {selectedAddress, cost, initialized: true}
  }

  render() {
    const { initialized, address, addressDisplay, ethBalance, networkError,
    data,
    selectedAddress, utils
    } = this.props;
    const displayAddress = Utils.addressShortened(address)

    let { cost } = data

    return (
      <div className="md:px-10 md:w-2/3 text-white h-auto"> 
        <div className="flex flex-row items-center justify-between">
          <div className = "md:w-1/2 p-2 text-left">
            <div className = "text-3xl font-bold md:py-5">
              TokenLaunch
            </div>
            <div className="">
              The development hub for stakeholders. Currently read-only.
            </div>
          </div>
        </div>

        <div className="md:flex py-3 text-center items-center justify-between">
          <div name="top-panel" className={"w-full absolute left-0 md:relative md:py-1 gap-y-2 flex flex-col "}>
              Cost to launch: {ethers.utils.formatEther(cost)} ETH.

              Modes: -Initial Supply
                    -Presale (fixed rate, unlimited supply)

              Tax:   -x % sent to owner. 


              UniSwap pair

              
          </div>
        </div>

      </div>
    )
  }

   static populateCampaigns = async(data, ethBalance, provider, signer, selectedAddress, getToken) => {
    const {token, gasCost} = data;
    for (let campaign of data.campaigns) {
      const {address} = campaign;
      if (token) {
        campaign.token = token
      } else {
        return {...data, campaigns: []}
      }

      //Populate / upconvert

      campaign.funding = zero;
      campaign.contribution = zero;
      campaign.progress = "0";
      
    }
    return data;
  }

  static contributeGasCost = (safeGasPrice) => {
    let v = BigNumber.from("85000").mul(safeGasPrice).mul(10**9)
    let fee = ethers.utils.formatEther(v)
    return v
  }

  static refreshCampaign = async (data, campaign, provider, selectedAddress, ethBalance) => {

  }

  static updateBalances = async(provider, data, selectedAddress, ethBalance, safeGasPrice, utils) => {
    if (!data || !data.campaigns) {
      console.log("PINEAPPLE 2 NO DATA")
      return data
    }
    for (let campaign of data.campaigns) {
      this.refreshCampaign(data, campaign, provider, selectedAddress, ethBalance)
    }

    return data
  }

  static handleConnectedWallet = async (data, utils) => {
    let {token} = data
    token = await utils.getToken(token.address, true);
    return {...data, token};
  }
 
}

export default TokenLaunch
