import React from "react";
import { ethers, BigNumber} from "ethers";
import Utils from '../Utils'

import Post from './Post'
import Profile from './Profile'
import TokenSelect from '../TokenSelect'

const optiSwap = require("../../contracts/opti-swap-address.json");
const supportedTokens = require("../../contracts/supported-tokens.json");
const OptiDaoData = require("../../contracts/optidao-data.json");

const zero = BigNumber.from("0")

export class OptiDao extends React.Component {
  static optiComponentName = "OptiDao";

  state = {
    info: false
  }

  static loading = (address) => {
    return `Loading OptiDao: ${address}`
  }

  approveCampaign = async(campaign) => {
    let {data, makeTransaction} = this.props
    let {token} = data
    let contract = token.contract;
    await makeTransaction(contract, "approve", [campaign.address, campaign.commitment])
  }

  fundCampaign = async(campaign, weibn) => {
    let {data, makeTransaction} = this.props
    await makeTransaction(campaign.contract, "fund", [], weibn)
  }

  static startingData = async(provider, signer, routeInfo, selectedAddress, ethBalance, safeGasPrice, utils) => {
    const {getToken} = utils
    const {tokenAddress} = routeInfo;

    let data = {selectedAddress, campaigns:[]};
    let cachedCampaigns;
    if (tokenAddress === "") {
      cachedCampaigns = OptiDaoData["topCampaigns"]
      console.log("Top campaigns")
    } else {
      cachedCampaigns = OptiDaoData[tokenAddress];
    }
    const gasCost = this.contributeGasCost(safeGasPrice)
    if (cachedCampaigns) {
      data = {...data, gasCost: gasCost, token: await getToken(tokenAddress), campaigns: cachedCampaigns, initialized: false}
      data = await this.populateCampaigns(data, ethBalance, provider, signer, selectedAddress, getToken);
      data.initialized = true
      return data
    }
    return {selectedAddress, token: getToken(tokenAddress), campaigns:[], initialized: true}
  }

  render() {
    const { initialized, address, addressDisplay, ethBalance, networkError,
    data,
    selectedAddress, utils
    } = this.props;
    let {token} = data
    const displayAddress = Utils.addressShortened(address)

    let { campaigns, selectedCampaign } = data
    
    if (selectedCampaign) {
      console.log("Selected a single campaign")
    }
    return (
      <div className="md:px-10 md:w-2/3 text-white h-auto"> 
        <div className="flex flex-row items-center justify-between">
          <div className = "md:w-1/2 p-2 text-left">
            <div className = "text-3xl font-bold md:py-5">
              {token && token.name} TokenZone
            </div>
            <div className="">
              The development hub for stakeholders. Currently read-only.
            </div>
          </div>
          {token &&
            <div className="w-full content-center items-center justify-center">
                <img alt="logo" className="h-32 w-32" 
                src={token.logo} />
            </div>}
        </div>

        <div className="md:flex py-3 text-center items-center justify-between">
        <div name="top-panel" className={"w-full absolute left-0 md:relative md:py-1 gap-y-2 flex flex-col " + (selectedCampaign ? "md:rounded-t-xl" : "md:rounded-xl")}>
          {campaigns.map((campaign) => {
              return (
                <Post key={campaign.address} campaign={campaign}
                 utils={utils} token={token}
                 selectCampaign={this.selectCampaign} 
                 approveCampaign={this.approveCampaign}
                 fundCampaign={this.fundCampaign}
                 selectedCampaign={selectedCampaign} selectedAddress={selectedAddress} />)
            }
            )}
              </div>
        </div>

      </div>
    )
  }

   static populateCampaigns = async(data, ethBalance, provider, signer, selectedAddress, getToken) => {
    const {token, gasCost} = data;
    for (let campaign of data.campaigns) {
      const {address} = campaign;
      if (token) {
        campaign.token = token
      } else {
        return {...data, campaigns: []}
      }

      //Populate / upconvert

/*      campaign.funding = ethers.BigNumber.from(campaign.funding);
      campaign.fundingGoal = ethers.BigNumber.from(campaign.fundingGoal);


      campaign.voteEndDate   = ethers.BigNumber.from(campaign.goalDate);
      campaign.payoutDate   = ethers.BigNumber.from(campaign.goalDate);*/
      campaign.funding = zero;
      campaign.contribution = zero;
      campaign.progress = "0";

/*      if (campaign.crowdFund) {
        campaign.contract =
          new ethers.Contract(
            address,
            OptiDaoCrowdFundArtifact.abi,
            signer
          );
      }*/

      //await this.refreshCampaign(data, campaign, provider, selectedAddress, ethBalance);
      
    }
    return data;
  }

  static contributeGasCost = (safeGasPrice) => {
    let v = BigNumber.from("85000").mul(safeGasPrice).mul(10**9)
    let fee = ethers.utils.formatEther(v)
    return v
  }

  static refreshCampaign = async (data, campaign, provider, selectedAddress, ethBalance) => {

    const {gasCost} = data;

    if (campaign.status !== "done") {
      campaign.mintedLP = await campaign.contract.mintedLP();
      if (!campaign.mintedLP.isZero()) {
        campaign.status = "done";
        campaign.ethFunded = await campaign.contract.ethMatchEstimate();
        campaign.lockedUntil = await campaign.contract.withdrawalsLockedUntilTimestamp();
        campaign.optiVault = await campaign.contract.optiVault();
      }
    }

    if (campaign.status === "new") {
      if (await campaign.contract.supplierHasCommitedBalance()) {
        campaign.status = "funding"
      } 
    }

    if (campaign.status === "funding") {
      campaign.balance = await provider.getBalance(campaign.address)
      campaign.ethGoal = await campaign.contract.ethMatchEstimate()
      campaign.progress = campaign.balance.mul(100).div(campaign.ethGoal).toString();
      if (campaign.balance.gte(campaign.ethGoal)) {
        campaign.progress = "100"
        campaign.status = "full"
      }

      let maximumAmount = zero;
      maximumAmount = campaign.ethGoal.sub(campaign.balance);
      if (maximumAmount.gt(ethBalance)) {
        maximumAmount = ethBalance;
        if (maximumAmount.gt(gasCost)) {
          maximumAmount = maximumAmount.sub(gasCost);
        }
      }
      campaign.maximumAmount = maximumAmount;
    }

    if (selectedAddress && campaign.contract) {
      campaign.contribution = await campaign.contract.ethContributionOf(selectedAddress)
      if (campaign.status === "done") {
        if (selectedAddress) {
          //campaign.lpShares = await campaign.contract.sharesOf(selectedAddress) 
        }
      }
    }
  }

  static updateBalances = async(provider, data, selectedAddress, ethBalance, safeGasPrice, utils) => {
    if (!data || !data.campaigns) {
      console.log("PINEAPPLE 2 NO DATA")
      return data
    }
    for (let campaign of data.campaigns) {
      this.refreshCampaign(data, campaign, provider, selectedAddress, ethBalance)
    }

    return data
  }

  static handleConnectedWallet = async (data, utils) => {
    let {token} = data
    token = await utils.getToken(token.address, true);
    return {...data, token};
  }
 
}

export default OptiDao
