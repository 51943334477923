import React from "react";
import HomeCardTitle from './HomeCardTitle';
import HomeCardCheckMarks from './HomeCardCheckMarks';

export default function HomeCardOTCSwap() {
  return (
    <div>
      <HomeCardTitle
        title="OTCSwap"
        icon="icon-hand.svg"/>
      <HomeCardCheckMarks 
        title={"OTCSwap"}
        onClick={() => window.open("https://otcswap.pro")}
        items={["Trustless over-the-counter trades",
            "Buy and sell from open orderbooks",
            "Low-gas limit orders"]}

        buttonText={"OTCSwap Pro"}
        getInfo={() => {
                    window.open("https://resource.optiswap.pro/ethereum-contracts/otcswap")
                  }}
/>
    </div>)

}
