import React from "react";
import HomeCardTitle from './HomeCardTitle';
import HomeCardCheckMarks from './HomeCardCheckMarks';

export default function HomeCardBonusSwap({token, routeCallback, tokenSelectionRouteCallback}) {
  let buttonText = "Buy with BonusSwap"
  let tokenAddress = "0xfAd45E47083e4607302aa43c65fB3106F1cd7607"
  if (token) {
    buttonText = `Buy ${token.symbol} with OptiSwap`
    tokenAddress = token.address;
  }

  return (
    <div className="">
      <HomeCardTitle className=""
        title="BonusSwap"
        icon="icon-shield-alt.svg"/>
      <HomeCardCheckMarks 
        routeCallback={routeCallback}
        items={["Treasury-funded tax subsidy",
            "DAO-approved trade bonus",
            "Extra 4.5% HOGE on each buy"]}
        onClick={async() => {
                    await tokenSelectionRouteCallback(`#bonus/${tokenAddress}`, tokenAddress)
                  }}

        buttonText={buttonText}/>
    </div>)
}
