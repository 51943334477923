import React from "react";
import { ethers } from "ethers";
import Button from '../Button'
import Utils from '../Utils'

export default class CrowdFund extends React.Component {

  render() {
    const { crowdfund, utils} = this.props
    const {balance, fundingGoal, goalDate, status} = crowdfund
    const progress = 100* balance / fundingGoal
    return (
      <div className={"rounded-xl my-3"}>   

          <div className="relative p-3">

            <div className="w-full border-2 border-black content-center h-20 bg-gray-400 rounded-r-full">
                <div className={"h-full bg-emerald-700 text-center rounded-r-full"} style={{"width": progress +"%"}}>  
                </div>
            </div>
          </div>

        {status == "funding" &&
          <div className= "w-full h-full md:flex flex-row justify-between items-center font-bold">
            <div className = "md:w-1/3">
            {utils.ethDisplay(ethers.BigNumber.from(fundingGoal))} ETH
            </div>
          </div>
        }
        {status == "complete" &&
          <div className= "w-full h-full md:flex flex-row justify-between items-center font-bold">
            <div className = "md:w-1/3">
            </div>
            <div className="md:w-1/3 p-3">
                Funded!            </div>
          </div>
        }

      </div>
    )
  }
}
