import React from "react";
import HomeCardTitle from './HomeCardTitle';
import HomeCardCheckMarks from './HomeCardCheckMarks';

export default function HomeCardSwap({token, routeCallback, tokenSelectionRouteCallback, getInfoCallback}) {
  let buttonText = "Swap"
  let tokenAddress = "0xfAd45E47083e4607302aa43c65fB3106F1cd7607"
  if (token) {
    buttonText = `Swap ${token.symbol}`
    tokenAddress = token.address;
  }

  return (
    <div>
      <HomeCardTitle
        title="OptiSwap"
        icon="icon-shield-alt.svg"/>
      <HomeCardCheckMarks 
        routeCallback={routeCallback}
        items={["Enhanced front-run protection",
            "Gas-aware order analysis",
            "Single-click order optimization"]}
        onClick={async() => {
                    await tokenSelectionRouteCallback(`#swap/${tokenAddress}`, tokenAddress)
                  }}
        getInfo={() => {
                    window.open("https://resource.optiswap.pro/optiswap")
                  }}
        buttonText={buttonText}/>
    </div>)
}
