import React from 'react'

export default function({balanceDisplay, goalDisplay, goalDateDisplay, goalReached, progress}) {
  return (
    <div className="relative p-3 md:h-20">

      <div className= "w-full h-full md:flex flex-row justify-between md:absolute items-center font-bold">
        <div className = "md:w-1/3">
        {balanceDisplay}/{goalDisplay} ETH
        </div>
        <div className="md:w-1/3">
        {goalDateDisplay}
        </div>
      </div>

      <div className="w-full border-2 border-black content-center h-20 bg-gray-400 rounded-r-full">
          <div className={"h-full bg-emerald-700 text-center rounded-r-full"} style={{"width": progress +"%"}}>  
          </div>
      </div>
    </div>
  )
}






